// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-icons/font/bootstrap-icons';

@import 'material-icons/iconfont/material-icons.css';

@import '~bootstrap-select/sass/bootstrap-select.scss';

