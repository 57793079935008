@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --sdb-w: 160px;
    --green: rgb(88, 214, 141);
    --danger: rgba(203, 67, 53, 0.4);
    --border: rgb(213, 219, 219);
    --primary: rgb(52, 152, 219);
    --text: rgb(52, 73, 94);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
    outline: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    background: #f1f3f6;
    font-size: 14px;
    color: rgb(52, 73, 94);
    font-weight: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.4px;
    overflow-x: hidden !important;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

/** HTML Helper */

a {
    text-decoration: none;
}
input[type="search"]::placeholder {
    font-size: small;
    font-style: italic;
    color: rgb(179, 182, 183);
}

hr {
    display: block;
    width: 100%;
    border: 0;
    margin: 7px 0;
    height: 1px;
    background: #229954;
}

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
}
table {
    width: 100%;
}
table th, table td {
    vertical-align: middle;
}
table th {
    color: rgb(93, 109, 126);
    font-weight: normal;
    text-transform: uppercase;
    font-size: 85%;
}
tr:last-child td {
    border: 0;
}

fieldset {
    position: relative;
    border: 1px solid var(--border);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 20px;
}

legend {
    position: absolute;
    top: -10px;
    left: 10px;
    text-align: center;
    font-size: 10pt;
    border: 1px solid var(--border);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    width: 100px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top,  #ffffff 0%, #e5e5e5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #ffffff 0%,#e5e5e5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #ffffff 0%,#e5e5e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e5e5e5',GradientType=0 ); /* IE6-9 */

}

strong {
    font-weight: 600;
}

::placeholder {
    font-size: .8em;
    font-style: italic;
  }
 

.alert {
    margin: 0;
    border: 0;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}
.alert-success {
    background-color: rgb(213, 245, 227);
    color: rgb(25, 111, 61);
    border: 1px solid rgb(171, 235, 198);
}
.alert-warning {
    background-color: rgb(254, 249, 231);
    color: rgb(169, 50, 38);
    border: 1px solid rgb(245, 183, 177);
}
.caption {
    font-size: 85%;
    color: rgb(93, 109, 126);
    text-transform: uppercase;
}
.edit:hover { 
    color: rgb(46, 204, 113); 
}
.delete:hover { 
    color: rgb(231, 76, 60); 
}
/** Bootstrap Helper */
.table > tbody > tr:nth-child(2n+1) > td, .table > tbody > tr:nth-child(2n+1) > th {
    background-color: rgb(244, 246, 247);
 }

.form-label {
    padding: 0;
    font-size: 95%;
}

.form-control, .form-select {
    padding: 7px 20px;
    -webkit-transition: width .2s ease-in-out;
    -moz-transition: width .2s ease-in-out;
    -o-transition: width .2s ease-in-out;
    transition: width .2s ease-in-out;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: rgb(255, 255, 255);
}
.form-control:focus, .form-select:focus {
    outline: none;
    border-color: var(--green) !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}
/* Success */
.text-success {
    color: var(--green) !important;
}
.bg-success, .btn-success {
    background: var(--green) !important;
}
/* primary */
.text-primary {
    color: var(--primary) !important;
}


.btn {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 0;

}

input[type="search"] {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}
.border-success{
    border:1px solid var(--green) !important;
}
.btn-outline-success {
    color: rgb(0, 0, 0);
    border:1px solid #0fcc45;
}
.btn-outline-success .bi {
    color:  #0fcc45;
    font-weight: bold;
    font-size: 16px;
}
.btn-outline-success:hover {
    background: #0fcc45;
    border: 0;
    color:#FFF;
    border:1px solid rgb(125, 206, 160);
}
.btn-outline-success:hover .bi {
    color:#FFF;
}

.dropdown-toggle::after {
    content: none;
}

.btn:active:focus {
    outline: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.card, .card-header, .card-footer, .card-body, .dropdown-menu {
    border: 0;
    background: rgb(255, 255, 255);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;

}.card, .dropdown-menu {
    /*box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;*/
}.card, .crad-header, .card-body, .card-footer {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
}

.card-footer {
    /*border-top: 1px solid rgb(229, 232, 232);*/
    /*background: rgb(247, 249, 249);*/

/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,f7f7f7+100 */
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(top,  #ffffff 0%, #f7f7f7 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #ffffff 0%,#f7f7f7 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #ffffff 0%,#f7f7f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=0 ); /* IE6-9 */

}
/*
.form-control {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border-right: 1px solid rgb(229, 232, 232);
    background: rgb(255, 255, 255);
}*/

/** Paginate */
.page-item, .page-link {
    border: 0;
    color: rgb(28, 40, 51);
    background: transparent !important;
}
.page-item.active .page-link {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    color: rgb(255, 255, 255);
    background:  rgb(28, 40, 51) !important;
}
.page-item .page-link:focus {
    outline: none;
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/** Main */

/* ClassicEditor */

.ck.ck-toolbar {
    /*background-color: #EEE !important;
    border: 1px solid var(--border-color)!important;*/
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
    /*padding-top: 7px !important;*/
    /*padding-bottom: 7px !important;*/
    margin-bottom: 8px !important;
    border: 1px solid #dee2e6 !important;
  }
  
  .ck-editor .ck-editor__editable_inline {
    min-height: 300px !important;
    /*border: 1px solid var(--border-color)!important;*/
    /*-webkit-border-radius: 6px !important;
    -moz-border-radius: 6px !important;
    border-radius: 6px !important;*/
    border: 1px solid #dee2e6 !important;
    overflow: auto;
  }
  .ck-editor .ck-editor__editable_inline:focuss {
    border:1 px solid #0fcc45 !important;
  }
/** Header */
.header-section {
    background: rgb(255, 255, 255);
    line-height: 50px;
    height: 50px;
    border-bottom: 1px solid var(--border);
}.header-section .menu-toggle {
    float: left;
    height: 49px;
    width: 60px;
    text-align: center;
    font-size: 18px;
    border-right: 1px solid var(--border);
    background: rgb(244, 246, 247); 
}.header-search-form {
    float: left;
}.header-search-form input[type="search"] {
    border: 1px solid var(--border);
    height: 20px;
    padding: 4px 16px;
    height: 36px;
    margin: 0 8px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
}.header-search-form button {
    border: 0;
    background: transparent !important;
    color: rgb(179, 182, 183);
    margin-right: 10px;
}
.items-nav a {
    display: block;
    padding: 0 20px;
    font-size: 22px;
    color: rgb(133, 146, 158);
}.items-nav a:hover { background: rgb(248, 249, 249); }

.avatars {
    display: inline-flex;
    flex-direction: row-reverse;
}
.avatar {
    display: block;
    overflow: hidden;
    border: 3px solid #FFF;
    position: relative;
    z-index: 101;
} 
.avatar img {
    display: block;
    width: 100%;
}
.avatars .avatar {
    position: relative;
}
.avatars .avatar:not(:last-child) {
    margin-left: -20px;
}
.avatar-xs { width: 40px; height: 40px; }
.avatar-sm { width: 60px; height: 60px; }
.avatar-md { width: 80px; height: 80px; }
.avatar-lg { width: 120px; height: 120px; }
 

.status-user {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    background: rgb(255, 255, 255);
}

div.online-indicator {
    margin: 4px auto;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #0fcc45;
    border-radius: 50%;
    position: relative;
  }
span.blink {
    display: block;
    width: 8px;
    height: 8px;
    background-color: #0fcc45;
    opacity: 0.7;
    border-radius: 50%;
    animation: blink 1s linear infinite;
  }

/*Animations*/
@keyframes blink {
    100% { transform: scale(2, 2); 
            opacity: 0;
          }
  }



.border-left {
    border-left: 1px solid rgb(229, 232, 232);
}

/** Sidebar */
.sidebar {
    background: rgb(244, 246, 247);
    min-width: var(--sdb-w);
    -webkit-transition: margin-left 0.2s ease-out;
    -moz-transition: mmargin-left 0.2s ease-out;
    -o-transition: margin-left 0.2s ease-out;
    transition: margin-left 0.2s ease-out;
    margin-left: 0;
}
.sidebar.toggle {
    margin-left: calc(var(--sdb-w) * -1);
}
.sidebar a {
    color: var(--text)
}
/* media
*------------------------------------------------- */
@media (max-width: 768px) {
    .sidebar {
        width: 0;
        margin-left: calc(var(--sdb-w) * -1);
    }
    .sidebar.toggle {
        min-width: var(--sdb-w);
        margin-left: 0;
    }
  }
  @media (max-width: 575px) {
    
  }
.sidebar .navbar-brand {
    display: block;
    height: 50px;
    line-height: 50px;
    width: 100%;
    letter-spacing: 0.1em;
    border-bottom: 1px solid var(--border);
    font-weight: 600;
}
/* User card */
.user-card .avatar { 
    position: relative;
    margin: 0 auto;
}

/* Menu */
.sidebar .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0; 
}.sidebar .menu ul li {
    padding: 0;
    margin: 0; 
}.sidebar .menu ul li:first-child a {
    border-top: 1px solid var(--border);;
}.sidebar .menu ul li a {
    display: block;
    border-bottom: 1px solid var(--border);
    padding: 10px 0 10px 20px;
}.sidebar .menu ul li a span {
    padding: 0 7px;
}.sidebar .menu ul li.active a {
    background: rgb(255,255,255);
}.sidebar .menu ul li.active a .bi{
    color: #0fcc45;
}.sidebar .menu ul li:hover a {
    color: rgb(28, 40, 51);
    background: rgb(251, 252, 252);
}.sidebar ul.sub-menu li:first-child a {
    border-top: 0;
}.sidebar ul.sub-menu li.active a {
    font-weight: 600;
}

/** Main */ 
main {
    border-left: 1px solid var(--border);
}
.breadcrumb {
    margin: 0; 
}.breadcrumb .breadcrumb-item a {
    color: rgb(93, 109, 126);
}

.border-right {
    border-right: 1px solid rgb(229, 232, 232);
}
.shadow-small {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}


.divider {
	position: relative;
	height: 1px;
}.div-transparent:before {
	content: "";
	position: absolute;
	top: 0;
	left: 1%;
	right: 1%;
	width: 98%;
	height: 1px;
	background-image: linear-gradient(to right, transparent, rgb(229, 232, 232), transparent);
}



/** Footer */
.footer {
    line-height: 50px;
    border-top: 1px solid var(--border);
    background: rgb(255, 255, 255);
}.footer .copyright {
    font-size: 12px;
    color: rgb(86, 101, 115);
}

ul li:first-child a.up,
ul li:last-child a.down {
  display: none;
}

.box a.active {
    color: #0fcc45 !important;
}

.panel-resizable {
    resize: vertical;
    overflow: hidden;
  }

